import { defineCustomElement, BaseController } from 'custom-elements-helpers';

defineCustomElement('cake-toggle', {
	attributes: [
		{ attribute: 'target', type: 'string' },
		{ attribute: 'toggle', type: 'string' },
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.body = document.querySelector('body');

			if (this.target) {
				this.elements.target = document.querySelector(this.target);
			}
		}

		bind() {
			this.on('click', () => {
				if (this.elements.target) {
					this.elements.target.classList.toggle(this.toggle);
					this.body.classList.toggle('has-active-navigation');
				}
			});
		}
	},
});
