import { defineCustomElement, BaseController } from 'custom-elements-helpers';

defineCustomElement('cake-gallery', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.images = Array.from(this.el.querySelectorAll('.js-cake-gallery-item'));
			this.elements.target = document.querySelector('.js-cake-gallery');
			this.index = 0;
			this.elements.label = this.el.querySelector('.js-cake-gallery-label');
		}

		bind() {
			this.on('click', () => {
				if (this.elements.images.length > 1) {
					this.index = (this.index === 0) ? 1 : 0;
					const photo = this.elements.images[0].getAttribute('style');
					const illustration = this.elements.images[1].getAttribute('style');

					this.elements.images[0].setAttribute('style', illustration);
					this.elements.images[1].setAttribute('style', photo);
					this.toggleLabel();
				}
			});

			if (document.querySelector('body').classList.contains('input-mouse') &&
				document.documentElement.clientWidth >= 720) {
				this.on('mouseenter', () => {
					this.showHoverTitle();
				});

				this.on('mouseleave', () => {
					this.hideHoverTitle();
				});

				this.on('mousemove', (e) => {
					this.moveHoverTitle(e.pageX, e.pageY);
				});
			}
		}

		toggleLabel() {
			this.elements.label.innerHTML = `${this.index + 1}/2`;
		}

		showHoverTitle() {
			if (!this.elements.label.classList.contains('is-shown')) {
				this.elements.label.classList.add('is-shown');
			}
		}

		hideHoverTitle() {
			if (this.elements.label.classList.contains('is-shown')) {
				this.elements.label.classList.remove('is-shown');
			}
		}

		moveHoverTitle(offsetX, offsetY) {
			this.elements.label.style.left = `${offsetX}px`;
			this.elements.label.style.top = `${offsetY}px`;
		}
	},
});
