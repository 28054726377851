import { defineCustomElement, BaseController } from 'custom-elements-helpers';

defineCustomElement('cake-accordion', {
	attributes: [
		{ attribute: 'allow-multiple', type: 'bool' },
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.buttons = Array.from(this.el.querySelectorAll('[role="heading"] button'));
			this.elements.information = Array.from(this.el.querySelectorAll('[role="information"]'));
		}

		bind() {
			this.elements.buttons.forEach((button) => {
				this.on('click', (e, target) => {
					e.preventDefault();
					this.toggle(target);
				}, button);
			});
		}

		hide(button) {
			button.setAttribute('aria-expanded', 'false');

			const activeInfo = this.elements.information.find((item) => item.getAttribute('aria-labelledby') === button.id);

			activeInfo.setAttribute('hidden', 'hidden');
		}

		show(button) {
			if (!this.allowMultiple) {
				this.hideAll();
			}

			button.setAttribute('aria-expanded', 'true');

			const activeInfo = this.elements.information.find((item) => item.getAttribute('aria-labelledby') === button.id);

			activeInfo.removeAttribute('hidden');
		}

		toggle(button) {
			if (button.getAttribute('aria-expanded') === 'true') {
				this.hide(button);
			} else {
				this.show(button);
			}
		}

		hideAll() {
			this.elements.buttons.forEach((button) => {
				this.hide(button);
			});
		}
	},
});
